export const CLUSTER_LAYER_SIZE = 20

export const POINT_LAYER_SIZE = [1, 2]

export const LINE_LAYER_SIZE = 7
export const SELECTED_LINE_LAYER_SIZE = 14
export const CIRCLE_LAYER_SIZE = 7
export const SELECTED_CIRCLE_LAYER_SIZE = 10
export const LINE_LAYER_SIZE_OFFSET = 4

export const INTERPOLATION_ZOOM_MIN = 10
export const INTERPOLATION_ZOOM_MAX = 18

export const REFERENTIAL_SOURCE = "referential"
export const REFERENTIAL_LAYER = "referentialLayer"
export const REFERENTIAL_LAYER_CLICKABLE = "referentialLayerClickable"
export const NOTATION_SOURCE = "notation"
export const NOTATION_LAYER = "notationLayer"
export const NOTATION_LAYER_CLICK = "notationLayerClick"
export const DAMAGES_SOURCE = "damages"
export const DAMAGES_LAYER_POINT = "damagesLayer-point"
export const DAMAGES_LAYER_LINE = "damagesLayer-line"
export const CUT_ROADWORKS_LAYER_LINES = "cutRoadworks-lines"
export const CUT_ROADWORKS_LAYER_POINT = "cutRoadworks-point"
export const CUT_ROADWORKS_SOURCE = "cutRoadworksSource"
export const IMAGE_COLLECTION_SOURCE = "imageCollection"
export const IMAGE_COLLECTION_BORDER_LAYER = "imageCollectionBorder"
export const IMAGE_COLLECTION_LAYER = "imageCollectionLayer"
export const IMAGE_SELECTED_SOURCE = "imageSelectedSource"
export const IMAGE_SELECTED_BORDER_LAYER = "imageSelectedBorder"
export const IMAGE_SELECTED_LAYER = "imageSelectedLayer"
export const ROADWORK_LAYER = "roadworkLayer"
export const ROADWORK_SOURCE = "roadworkSource"
export const VERTICAL_SIGN_POLES_LAYER = "verticalSignPolesLayer"
export const VERTICAL_SIGN_POLES_SOURCE = "verticalSignPolesSource"
export const VERTICAL_SIGN_POLES_LAYER_CLUSTER = "verticalSignPolesCluster"
export const VERTICAL_SIGN_POLES_LAYER_CLUSTER_COUNT = "verticalSignPolesClusterCount"
export const HORIZONTAL_SIGNS_LAYER = "horizontalSignsLayer"
export const HORIZONTAL_SIGNS_SOURCE = "horizontalSignsSource"
export const HORIZONTAL_SIGNS_LAYER_CLUSTER = "horizontalSignsCluster"
export const HORIZONTAL_SIGNS_LAYER_CLUSTER_COUNT = "horizontalSignsClusterCount"
export const INCIDENTS_LAYER = "svraiIncidentsLayer"
export const INCIDENTS_LAYER_CLUSTER = "svraiIncidentsLayerCluster"
export const INCIDENTS_LAYER_CLUSTER_COUNT = "svraiIncidentsLayerClusterCount"
export const INCIDENTS_SOURCE = "svraiIncidentsSource"
export const INCIDENT_DETAILS_TRAJECTORY = "incidentDetailsTrajectoryLayer"
export const INCIDENT_DETAILS_END_POINT = "incidentDetailsEndPointLayer"
export const INCIDENT_DETAILS_POINT = "incidentDetailsPointLayer"
export const INCIDENT_DETAILS_TRAJECTORY_SOURCE = "incidentDetailsTrajectorySource"
export const INCIDENT_DETAILS_END_POINT_SOURCE = "incidentDetailsEndPointSource"
export const INCIDENT_DETAILS_POINT_SOURCE = "incidentDetailsPointSource"

export const layersZindexes = {
    [HORIZONTAL_SIGNS_LAYER_CLUSTER_COUNT]: -2,
    [HORIZONTAL_SIGNS_LAYER_CLUSTER]: -1,
    [HORIZONTAL_SIGNS_LAYER]: -1,
    [VERTICAL_SIGN_POLES_LAYER_CLUSTER_COUNT]: -2,
    [VERTICAL_SIGN_POLES_LAYER_CLUSTER]: -1,
    [VERTICAL_SIGN_POLES_LAYER]: -1,
    "road-number": 0,
    "road-number-shield": 0,
    [IMAGE_SELECTED_LAYER]: 8,
    [IMAGE_SELECTED_BORDER_LAYER]: 9,
    [IMAGE_COLLECTION_LAYER]: 10,
    [IMAGE_COLLECTION_BORDER_LAYER]: 11,
    [REFERENTIAL_LAYER_CLICKABLE]: 12,
    [CUT_ROADWORKS_LAYER_LINES]: 20,
    [CUT_ROADWORKS_LAYER_POINT]: 20,
    [DAMAGES_LAYER_POINT]: 30,
    [DAMAGES_LAYER_LINE]: 30,
    [INCIDENT_DETAILS_POINT]: 45,
    [INCIDENT_DETAILS_END_POINT]: 46,
    [INCIDENT_DETAILS_TRAJECTORY]: 47,
    [INCIDENTS_LAYER_CLUSTER_COUNT]: 48,
    [INCIDENTS_LAYER_CLUSTER]: 49,
    [NOTATION_LAYER]: 50,
    [ROADWORK_LAYER]: 50,
    [INCIDENTS_LAYER]: 50,
    [NOTATION_LAYER_CLICK]: 51,
    [REFERENTIAL_LAYER]: 100,
}
