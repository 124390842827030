import { useMemo } from "react"

import { useRoutes } from "react-router-dom"

import { HorizontalSignDetailsSidebar } from "../../containers/HorizontalSignDetailsSidebar"
import { HorizontalSigningSidebar } from "../../containers/HorizontalSigningSidebar"
import { HorizontalSignValidator } from "../HorizontalSignValidator"
import * as Styled from "./HorizontalSigningSidebarRouter.styled"

export function HorizontalSigningSidebarRouter() {

    const sidebarRoutes = useMemo(() => ([
        { path: "/", element: <HorizontalSigningSidebar /> },
        { path: "/:road", element: <HorizontalSigningSidebar /> },
        { path: "/:road/:signId", element: <HorizontalSignValidator><HorizontalSignDetailsSidebar /></HorizontalSignValidator> },
    ]), [])

    return <Styled.Sidebar>{useRoutes(sidebarRoutes)}</Styled.Sidebar>
}
