import { I18N_NAMESPACE } from "../../../common/constants/i18n"

export const VERTICAL_SIGNING_CONDITION_GOOD = 10
export const VERTICAL_SIGNING_CONDITION_MEDIUM = 20
export const VERTICAL_SIGNING_CONDITION_BAD = 30
export const VERTICAL_SIGNING_CONDITION_UNKNOWN = -1
export const VERTICAL_SIGNING_CONDITIONS = [
    VERTICAL_SIGNING_CONDITION_GOOD,
    VERTICAL_SIGNING_CONDITION_MEDIUM,
    VERTICAL_SIGNING_CONDITION_BAD,
    VERTICAL_SIGNING_CONDITION_UNKNOWN,
]

export const VERTICAL_SIGNING_CONDITIONS_VALUES = {
    [VERTICAL_SIGNING_CONDITION_BAD]: {
        backgroundColor: "colors/rouge/800",
        color: "DPE/rouge",
        label: "components.verticalSignsConditionCount.bad",
    },
    [VERTICAL_SIGNING_CONDITION_MEDIUM]: {
        backgroundColor: "colors/jaune/800",
        color: "DPE/orange",
        label: "components.verticalSignsConditionCount.medium",
    },
    [VERTICAL_SIGNING_CONDITION_GOOD]: {
        backgroundColor: "colors/vert logiroad/200",
        color: "objects/object-primary",
        label: "components.verticalSignsConditionCount.good",
    },
    [VERTICAL_SIGNING_CONDITION_UNKNOWN]: {
        color: "colors/gris/500",
        label: "",
    },
}

export const getVerticalSigningConditionsValues = (t) => ({
    [VERTICAL_SIGNING_CONDITION_BAD]: {
        ...VERTICAL_SIGNING_CONDITIONS_VALUES[VERTICAL_SIGNING_CONDITION_BAD],
        label: t(I18N_NAMESPACE, VERTICAL_SIGNING_CONDITIONS_VALUES[VERTICAL_SIGNING_CONDITION_BAD].label),
    },
    [VERTICAL_SIGNING_CONDITION_MEDIUM]: {
        ...VERTICAL_SIGNING_CONDITIONS_VALUES[VERTICAL_SIGNING_CONDITION_MEDIUM],
        label: t(I18N_NAMESPACE, VERTICAL_SIGNING_CONDITIONS_VALUES[VERTICAL_SIGNING_CONDITION_MEDIUM].label),
    },
    [VERTICAL_SIGNING_CONDITION_GOOD]: {
        ...VERTICAL_SIGNING_CONDITIONS_VALUES[VERTICAL_SIGNING_CONDITION_GOOD],
        label: t(I18N_NAMESPACE, VERTICAL_SIGNING_CONDITIONS_VALUES[VERTICAL_SIGNING_CONDITION_GOOD].label),
    },
    [VERTICAL_SIGNING_CONDITION_UNKNOWN]: {
        color: "colors/gris/500",
        label: "",
    },
})
