import { useCallback } from "react"

import { useNavigate } from "react-router-dom"

import { useUrlParams } from "@l2r-front/l2r-utils"

import { HorizontalSignsSidebarComponents } from "../../components/HorizontalSignsSidebarComponents"
import { useGetHorizontalSignTypeDepth } from "../../hooks/utils/useGetHorizontalSignTypeDepth"
import * as Styled from "./HorizontalSigningMobileContent.styled"

export const HorizontalSigningMobileContent = () => {
    const getHorizontalSignTypeDepth = useGetHorizontalSignTypeDepth()
    const navigate = useNavigate()
    const { getParam } = useUrlParams()

    const handleChangeFilter = useCallback((filters) => {
        const filterCodeDepth = getHorizontalSignTypeDepth(filters.type)
        if (filterCodeDepth % 2 === 1) {
            const condition = getParam("horizontalSigningCondition")
            navigate(`./code/${filters.type}?horizontalSigningType=${filters.type}${condition ? `&horizontalSigningCondition=${condition}` : ""}`)
        }
    }, [getParam, getHorizontalSignTypeDepth, navigate])

    return (
        <Styled.Container>
            <HorizontalSignsSidebarComponents handleChangeFilter={handleChangeFilter} />
        </Styled.Container>
    )
}