import { useNetworksStateContext } from "@l2r-front/l2r-networks"
import { EMPTY_QUERY_PARAM } from "@l2r-front/l2r-query"

import { horizontalSigningQueryKeys } from "./queryKeys"
import { useHorizontalSigningQuery } from "./useHorizontalSigningQueries"

function sortChildTypes(parent) {
    parent.children.sort((a, b) => a.code.localeCompare(b.code))

    parent.children.forEach(child => sortChildTypes(child))
}

export function useHorizontalSignTypes(parent = EMPTY_QUERY_PARAM, config = {}) {
    const url = "horizontal_sign_types/"
    const { selectedNetwork } = useNetworksStateContext()
    const { country } = selectedNetwork

    const parameters = {
        country,
        parent: parent || EMPTY_QUERY_PARAM,
    }

    const queryKey = horizontalSigningQueryKeys.list(parameters)

    const { data, isLoading, isError, isFetching } = useHorizontalSigningQuery(
        url,
        queryKey,
        parameters,
        {
            ...config,
            refetchInterval: 7 * 60 * 1000,
            refetchIntervalInBackground: true,
        },
    )

    if (data) {
        data.sort((a, b) => a.code.localeCompare(b.code))
        data.forEach(d => sortChildTypes(d))
    }

    return { data, isLoading, isError, isFetching }
}

export function useHorizontalSignType(code, queryParameters, config = {}) {
    const url = `horizontal_sign_types/${code}/`
    const queryKey = horizontalSigningQueryKeys["horizontalSignType"]({ ...queryParameters, code })

    const { data, isLoading, isError } = useHorizontalSigningQuery(
        url,
        queryKey,
        { ...queryParameters },
        {
            enabled: !!code,
            ...config,
        },
    )

    if (data) {
        sortChildTypes(data)
    }

    return { data, isLoading, isError }
}