import { useMemo } from "react"

import { useParams } from "react-router-dom"

import { useTranslation } from "@l2r-front/l2r-i18n"
import { RoadDetailInfo } from "@l2r-front/l2r-networks"
import { Typography, useIsMobileDevice } from "@l2r-front/l2r-ui"

import { I18N_NAMESPACE } from "../../../../common/constants/i18n"
import { DETAIL_TAB_PARAM_KEY } from "../../../../common/constants/tabKeys"
import { DEFAULT_SIGN_ICON } from "../../constants/defaultIcon"
import { VERTICAL_SIGNING_CONDITIONS_VALUES } from "../../constants/verticalSigningConditions"
import { useVerticalSigningStateContext } from "../../contexts/VerticalSigningContext"
import { usePoleWithVerticalSigns } from "../../hooks/queries/verticalSigning/usePoleWithVerticalSigns"
import { useDescendantCodes } from "../../hooks/utils/useDescendantCodes"
import { VerticalSignDetails } from "../VerticalSignDetails"
import * as Styled from "./VerticalSignPoleDetailsSidebarContent.styled"

export function VerticalSignPoleDetailsSidebarContent() {

    const { poleId, road } = useParams()
    const { data, isError } = usePoleWithVerticalSigns(poleId)
    const { filters } = useVerticalSigningStateContext()
    const filterDescendantCodes = useDescendantCodes(filters.type)
    const isMobile = useIsMobileDevice()

    const { t } = useTranslation(I18N_NAMESPACE)

    const latLong = data?.geometry.coordinates

    const LatLongComponent = useMemo(() => {
        if (latLong) {
            return <Typography id="road-detail-latlong" variant="Regular">
                {latLong[0].toFixed(6)}, {latLong[1].toFixed(6)}
            </Typography>
        }
        return <Styled.Skeleton />
    }, [latLong])

    const displayedTabIndex = useMemo(() => {
        if (filters?.type && filterDescendantCodes && data) {
            const filterIndex = data.vertical_signs.findIndex(verticalSign => {
                if (filters.conditions) {
                    return filterDescendantCodes.includes(verticalSign.code) && filters.conditions.includes(verticalSign.condition)
                } else {
                    return filterDescendantCodes.includes(verticalSign.code)
                }
            })
            return filterIndex >= 0 ? filterIndex : null
        } else if (filters.conditions && data) {
            const filterIndex = data.vertical_signs.findIndex(verticalSign => {
                return filters.conditions.includes(verticalSign.condition)
            })
            return filterIndex >= 0 ? filterIndex : null
        }
        return null
    }, [data, filterDescendantCodes, filters])

    const tabsAttributes = useMemo(() => {
        if (data) {
            return data.vertical_signs.map((verticalSign, index) => ({
                element: <VerticalSignDetails verticalSign={verticalSign} poleId={poleId} />,
                icon: <Styled.IconWrapper color={VERTICAL_SIGNING_CONDITIONS_VALUES[verticalSign.condition].backgroundColor}>
                    <Styled.Icon
                        alt={`icon-${verticalSign.code}`}
                        onError={e => e.target.src = DEFAULT_SIGN_ICON}
                        src={verticalSign.icon || DEFAULT_SIGN_ICON}
                    />
                </Styled.IconWrapper>,
                id: `tab-${verticalSign.code}-${index}`,
            }))
        }
        return null
    }, [data, poleId])



    return <Styled.Container isMobile={isMobile}>
        <Styled.RoadDetailWrapper>
            <Typography variant="H3">
                {t(I18N_NAMESPACE, "containers.verticalSignDetailSidebar.location")}
            </Typography>
            <RoadDetailInfo
                LengthComponent={LatLongComponent}
                lengthLabel={`${t(I18N_NAMESPACE, "containers.sidebarVerticalSignDetailContent.detail.title")} : `}
                road={road}
            />
        </Styled.RoadDetailWrapper>
        <Styled.Wrapper isMobile={isMobile}>
            <Typography variant="H2">
                {t(I18N_NAMESPACE, "containers.sidebarVerticalSignDetailContent.title")}
            </Typography>
            {
                isError ? <Styled.ErrorText>
                    {t(I18N_NAMESPACE, "containers.sidebarVerticalSignDetailContent.error")}
                </Styled.ErrorText>
                    : !tabsAttributes ? <Styled.TabSkeleton />
                        : <Styled.Tabs
                            defaultTab={displayedTabIndex}
                            paramKey={DETAIL_TAB_PARAM_KEY}
                            tabElement={Styled.Tab}
                            TabIndicatorProps={{ style: { display: "none" } }}
                            tabsConfig={tabsAttributes}
                            scrollButtons="auto"
                            variant="scrollable"
                        />
            }

        </Styled.Wrapper>
    </Styled.Container>
}
