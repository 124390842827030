import { useMemo } from "react"

import { useMatch } from "react-router-dom"

import { useHorizontalSigningStateContext } from "../../../modules/horizontalSigning/contexts/HorizontalSigningContext"
import { APP_ROAD } from "../../constants/appRoad"
import { MODULE_HORIZONTAL_SIGNING } from "../../constants/modules"
import { RoadContentRouter } from "../../routers/RoadContentRouter"
import { RoadDesktopMapRouter } from "../../routers/RoadDesktopMapRouter"
import { SidebarRouter } from "../../routers/SidebarRouter"
import * as Styled from "./RoadDesktopLayout.styled"

export function RoadDesktopLayout() {
    const { ascendantCodes } = useHorizontalSigningStateContext()
    const matchHorizontalSigning = useMatch(`:slugId/${APP_ROAD}/${MODULE_HORIZONTAL_SIGNING}/*`)
    const matchHorizontalSigningDetail = useMatch(`:slugId/${APP_ROAD}/${MODULE_HORIZONTAL_SIGNING}/:road/:signId`)

    const sidebarCount = useMemo(() => {
        if (!matchHorizontalSigning) {
            return 1
        }

        return matchHorizontalSigningDetail ? 1 : Math.floor(ascendantCodes.length / 2) + 1
    }, [ascendantCodes, matchHorizontalSigning, matchHorizontalSigningDetail])

    return (
        <Styled.Wrapper>
            <SidebarRouter />
            <RoadContentRouter />
            <Styled.Map sidebarCount={sidebarCount}>
                <RoadDesktopMapRouter />
            </Styled.Map>
        </Styled.Wrapper>
    )
}
